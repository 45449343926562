import React from 'react';

import Image from '@/components/Image';
import PlaceHolderImage from '@/components/PlaceHolderImage';
import { useIsMobile } from '@/hooks';

import CompetitionIcon from '@/images/icons/competitions.svg';

import styles from './styles.module.scss';

interface Props {
  entry: CompetitionsEntryFragment;
}

const CompetitionsCard = ({ entry }: Props) => {
  const [isMobile] = useIsMobile(768);
  return (
    <a className={styles.competitionCard} href={`/competitions/${entry.slug}`}>
      <div className={styles.hero}>
        <div className={styles.image}>
          {entry?.thumbnail?.[0]?.jpg ? <Image {...entry?.thumbnail?.[0]} /> : <PlaceHolderImage />}
        </div>
        <div className={styles.competitionTag}>
          <CompetitionIcon />
        </div>
        <h2>{entry.title}</h2>
        {entry.body && !isMobile && <div dangerouslySetInnerHTML={{ __html: entry.body?.substr(0, 80) }} />}
      </div>
    </a>
  );
};

export default CompetitionsCard;
