import React from 'react';
import cc from 'classcat';

import Image from '@/components/Image';
import PlaceHolderImage from '@/components/PlaceHolderImage';

import EventTag from '@/images/icons/event.svg';
import NewsTag from '@/images/icons/news.svg';

import styles from './styles.module.scss';

interface Props {
  entry: NewsEntryFragment;
}

const NewsCard = ({ entry }: Props) => {
  const isNews = entry.newsTag === 'news';

  return (
    <a className={styles.newsCard} href={`/news/${entry.slug}`}>
      <div className={styles.hero}>
        <div className={styles.image}>
          {entry?.thumbnail?.[0]?.jpg ? <Image {...entry.thumbnail?.[0]} /> : <PlaceHolderImage />}
        </div>
        <div className={cc({ [styles.newsTag]: isNews, [styles.eventTag]: !isNews })}>
          {isNews ? <NewsTag /> : <EventTag />}
        </div>
        <h2>{entry.title}</h2>
        {entry.body && <div className={styles.body} dangerouslySetInnerHTML={{ __html: entry.body?.substr(0, 60) }} />}
      </div>
    </a>
  );
};

export default NewsCard;
