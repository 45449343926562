import React, { useMemo, useRef } from 'react';
import cc from 'classcat';
import { useMutation, useQuery } from 'urql';

import CompetitionCard from '@/components/CompetitionsCard';
import NewsCard from '@/components/NewsCard';
import OfferCard from '@/components/OfferCard';

import ChevronIcon from '@/images/icons/chevron--fat.svg';

import query from '../../graphql/queries/user.graphql';
import mutation from './mutation.graphql';

import styles from './styles.module.scss';

interface Props {
  title?: string;
  cards?: Array<
    | OfferFragment
    | ProductOfferFragment
    | NewsEntryFragment
    | ETicketFragment
    | CompetitionsEntryFragment
    | GiftCardFragment
  >;
  slider?: boolean;
  small?: boolean;
  whiteText?: boolean;
}
const CardGrid = ({ cards, title, slider, whiteText, small }: Props) => {
  const [{ data }] = useQuery<UserQuery, UserQueryVariables>({ query });
  const wishlist = useMemo(() => (!!data ? data?.userDetails?.wishlist?.map((w) => w!.id!) : []), [data]);
  const [, mutate] = useMutation(mutation);
  const cardGridRef = useRef<HTMLDivElement>(null);

  const scrollAmount = 400;
  const [fullScroll, setFullScroll] = React.useState(false);
  const [scrollDist, setScrollDist] = React.useState(scrollAmount);

  function scrollCards() {
    if (cardGridRef != null && cardGridRef.current) {
      if (!fullScroll) {
        setScrollDist(scrollDist + scrollAmount);
      } else {
        setFullScroll(false);
        setScrollDist(scrollAmount);
      }
      cardGridRef.current.scrollLeft = scrollDist;
      if (scrollDist > cardGridRef.current.scrollWidth - cardGridRef.current.clientWidth) {
        setFullScroll(true);
        setScrollDist(0);
      }
    }
  }

  const renderCards = () => {
    return cards?.map(
      (
        c:
          | OfferFragment
          | ProductOfferFragment
          | NewsEntryFragment
          | ETicketFragment
          | CompetitionsEntryFragment
          | GiftCardFragment,
      ) => {
        switch (c.__typename) {
          case 'specialOffers_specialOffers_Entry':
          case 'specialOffers_productOffers_Entry':
          case 'eTickets_Product':
          case 'giftCards_Product':
            return (
              <OfferCard
                offer={c}
                key={c.slug}
                wishlist={wishlist}
                addToWishlist={(id: string) => mutate({ id: id })}
              />
            );
          case 'news_news_Entry':
            return <NewsCard entry={c} key={c.slug} />;
          case 'competitions_competitions_Entry':
            return <CompetitionCard entry={c} key={c.slug} />;
          default:
            return null;
        }
      },
    );
  };

  return !!cards?.length ? (
    <div className={cc({ [styles.cardGrid]: true, [styles.whiteText]: whiteText })}>
      {title && (
        <h3 className={styles.title} data-test-scroll-dis={scrollDist}>
          {title}{' '}
          {slider && (
            <button type="button" className={styles.scroller} onClick={scrollCards}>
              <ChevronIcon />
            </button>
          )}
        </h3>
      )}
      <div
        ref={cardGridRef}
        data-test-full-scroll={fullScroll}
        className={cc({
          [styles.entries]: true,
          [styles.slider]: slider,
          [styles.small]: small,
        })}
      >
        {renderCards()}
      </div>
    </div>
  ) : null;
};

export default CardGrid;
